<template>
  <div class="privacy-policy">
    <div v-if="currentPrivacyText" class="privacy-content">
      <!-- Customer specific header if available -->
      <div v-if="currentPrivacyText.headerImage" class="customer-header">
        <img 
          :src="currentPrivacyText.headerImage"
          :alt="currentPrivacyText.customer + ' Header'"
          class="w-100"
        />
      </div>
      <div v-else>
        <div class="default-header">
          <h1>{{ $t('common.legal-texts') }}</h1>
        </div>
      </div>
      
      <!-- Privacy content -->
      <div class="privacy-container">
        <div class="privacy-header">
          <h1 class="title">{{ currentTranslation.title }}</h1>
          <p class="last-update">
            Última actualización: {{ privacyLastUpdate }}
          </p>
        </div>
        
        <!-- Render HTML content safely -->
        <div v-html="currentTranslation.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { privacyTexts } from './PrivacityTextVersion'

export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      currentPrivacyText: null
    }
  },
  created() {
    // Get customer from route params or use default
    // console.log('this.$route.params.communityId', this.$route.params.communityId);
    const customer = this.$route.params.communityId?.toLowerCase() || 'default'
    console.log('customer', customer);
    this.currentPrivacyText = privacyTexts[customer] || privacyTexts.default
  },
  computed: {
    currentLocale() {
      return this.$route?.query?.locale || this.$store.state.locale.currentLocale || 'es'
    },
    currentTranslation() {
      if (!this.currentPrivacyText) return null
      const translations = this.currentPrivacyText.translations || privacyTexts.default.translations
      return translations[this.currentLocale] || translations.es // Fallback to Spanish
    },
    privacyLastUpdate() {
      return this.currentPrivacyText?.lastUpdate || privacyTexts.default.lastUpdate
    }
  }
}
</script>

<style lang="scss" scoped>
.privacy-policy {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  .customer-header {
    margin-bottom: 2rem;
    
    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  .default-header {
    height: 100px;
    background: var(--primary-color, #b9b5e5);
    border-radius: 8px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: white;
      margin: 0;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .privacy-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .privacy-header {
      margin-bottom: 2rem;

      .title {
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .last-update {
        color: #666;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
