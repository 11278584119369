export const privacyTexts = {
  'nexos': {
    customer: 'Nexos',
    headerImage: '/assets/customers/nexos/nexos-auth-header-short.png',
    lastUpdate: '20 de diciembre de 2024',
    translations: {
      es: {
        title: 'Autorización para el tratamiento de datos personales',
        content: `
          <p>
            Autorizo de manera voluntaria, previa, expresa e informada a Bancolombia S.A. para que recolecten, soliciten, almacenen, consulten, procesen, modifiquen, actualicen, aclaren, retiren, compartan o divulguen los datos aquí suministrados para las finalidades que se enuncian a continuación, en el marco del servicio (la Comunidad):
          </p>
          <ul>
            <li>Enviar notificaciones y actualizaciones relacionadas con la cuenta del Usuario en la Comunidad.</li>
            <li>Comprender cómo el Usuario navega e interactúa en la Comunidad para mejorar el servicio.</li>
            <li>Gestionar la relación con el Usuario.</li>
            <li>Realizar análisis sobre la navegabilidad del sitio.</li>
            <li>
              Compartir con terceros para el cumplimiento de obligaciones legales, cuando así lo requiera una orden judicial o por solicitud de una entidad gubernamental. Asimismo, la Comunidad podrá compartir dicha información cuando sea necesario para hacer cumplir los Términos de Servicio u otros acuerdos vigentes y en especial con proveedores de hosting, análisis de datos y envío de comunicaciones para mejorar la calidad del servicio.
            </li>
          </ul>
          <p>
            Así mismo, mediante la suscripción del presente formato, autorizo a los responsables y encargados a corregir y/o actualizar dentro de sus bases de datos, la información aquí suministrada.
          </p>
          <p>
            Al registrarme en la Comunidad, conozco que se recopilarán datos personales como nombre completo y dirección de correo electrónico. También se recopilarán datos sobre el uso del servicio la Comunidad, incluyendo el historial de navegación, preferencias de usuario y actividad dentro de la comunidad.
          </p>
          <p>
            Declaro que se me ha informado sobre mis derechos, tales como conocer, rectificar mis datos personales, solicitar prueba de la autorización, ser informado sobre el tratamiento que se ha dado a mis datos personales, presentar quejas ante la Superintendencia de Industria y Comercio (SIC), revocar la autorización otorgada y/o solicitar la supresión de mis datos en los casos en que sea procedente. Estas solicitudes serán respondidas en el plazo dispuesto por la Ley 1581 de 2012, Decreto 1377 de 2013 y demás normas colombianas aplicables.
          </p>
          <p>
            Para conocer la Política para la administración de datos personales, ingrese al sitio web: <a href="https://www.grupobancolombia.com/personas/documentos-legales/proteccion-datos/bancolombia-sa/" target="_blank" rel="noopener noreferrer">https://www.grupobancolombia.com/personas/documentos-legales/proteccion-datos/bancolombia-sa/</a>
          </p>
        `
      }
    }
  },

  'nectios': {
    customer: 'Nectios',
    headerImage: '/assets/customers/nectios/auth-header-short.png',
    lastUpdate: '1/4/2022',
    translations: null  // Will use default translations
  },

  default: {
    customer: 'Default',
    lastUpdate: '20/12/2024',
    translations: {
      es: {
        title: 'Política de Privacidad',
        content: `
          <h2>Política de Privacidad y Protección de Datos</h2>
          
          <p>En cumplimiento de la normativa vigente en materia de protección de datos personales, le informamos de los siguientes aspectos:</p>
          
          <h3>1. ¿Quién es el responsable del tratamiento de sus datos?</h3>
          <p>
            <strong>Responsable:</strong> Copernic Technologies S.L.<br>
            <strong>Dirección:</strong> Plaça Doctor Letamendi 37, 08007 Barcelona, España<br>
            <strong>Email:</strong> legal@nectios.com<br>
            <strong>CIF:</strong> B67073130
          </p>

          <h3>2. ¿Con qué finalidad tratamos sus datos personales?</h3>
          <p>Tratamos la información que nos facilita con las siguientes finalidades:</p>
          <ul>
            <li>Gestionar el servicio contratado y mantener la relación contractual</li>
            <li>Atender sus consultas y solicitudes</li>
            <li>Enviarle comunicaciones comerciales sobre nuestros productos y servicios</li>
            <li>Mejorar nuestros servicios y la experiencia de usuario</li>
            <li>Cumplir con nuestras obligaciones legales</li>
          </ul>

          <h3>3. ¿Por cuánto tiempo conservaremos sus datos?</h3>
          <p>Los datos personales se conservarán mientras se mantenga la relación comercial y no se solicite su supresión. Incluso solicitada la supresión, se mantendrán bloqueados durante el tiempo necesario, y limitando su tratamiento, únicamente para:</p>
          <ul>
            <li>Cumplir con las obligaciones legales/contractuales de cualquier tipo a que estemos sometidos</li>
            <li>Resolver quejas o reclamaciones pendientes</li>
            <li>Hacer frente a cualquier responsabilidad que pudiera derivarse del tratamiento de los datos</li>
          </ul>

          <h3>4. ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
          <p>La base legal para el tratamiento de sus datos es:</p>
          <ul>
            <li>La ejecución del contrato de servicios</li>
            <li>El consentimiento del interesado</li>
            <li>El cumplimiento de obligaciones legales aplicables</li>
            <li>El interés legítimo del responsable</li>
          </ul>

          <h3>5. ¿A qué destinatarios se comunicarán sus datos?</h3>
          <p>Sus datos podrán ser comunicados a:</p>
          <ul>
            <li>Empresas del grupo Nectios para fines administrativos internos</li>
            <li>Proveedores de servicios necesarios para la prestación del servicio</li>
            <li>Administraciones Públicas en los casos previstos por la Ley</li>
          </ul>

          <h3>6. ¿Cuáles son sus derechos?</h3>
          <p>Tiene derecho a:</p>
          <ul>
            <li>Acceder a sus datos personales</li>
            <li>Rectificar los datos inexactos</li>
            <li>Solicitar su supresión</li>
            <li>Oponerse al tratamiento de sus datos</li>
            <li>Solicitar la limitación del tratamiento</li>
            <li>Solicitar la portabilidad de sus datos</li>
          </ul>

          <p>Puede ejercer estos derechos enviando un correo electrónico a legal@nectios.com, adjuntando copia de su DNI o documento identificativo equivalente.</p>

          <h3>7. Medidas de seguridad</h3>
          <p>Aplicamos medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo, incluyendo:</p>
          <ul>
            <li>Cifrado de datos</li>
            <li>Capacidad de garantizar la confidencialidad, integridad y disponibilidad</li>
            <li>Capacidad de restaurar el acceso a los datos</li>
            <li>Proceso de verificación y evaluación regular</li>
          </ul>
        `
      },
      en: {
        title: 'Privacy Policy',
        content: `
          <h2>Privacy and Data Protection Policy</h2>
          
          <p>In compliance with current regulations on personal data protection, we inform you of the following aspects:</p>
          
          <h3>1. Who is responsible for processing your data?</h3>
          <p>
            <strong>Controller:</strong> Copernic Technologies S.L.<br>
            <strong>Address:</strong> Plaça Doctor Letamendi 37, 08007 Barcelona, Spain<br>
            <strong>Email:</strong> legal@nectios.com<br>
            <strong>VAT:</strong> B67073130
          </p>

          <h3>2. For what purpose do we process your personal data?</h3>
          <p>We process the information you provide us for the following purposes:</p>
          <ul>
            <li>Managing the contracted service and maintaining the contractual relationship</li>
            <li>Addressing your queries and requests</li>
            <li>Sending commercial communications about our products and services</li>
            <li>Improving our services and user experience</li>
            <li>Complying with our legal obligations</li>
          </ul>

          <h3>3. How long will we keep your data?</h3>
          <p>Personal data will be kept for as long as the commercial relationship is maintained and you don't request its deletion. Even after requesting deletion, data will be kept blocked for the necessary time, limiting its processing, solely for:</p>
          <ul>
            <li>Complying with any legal/contractual obligations we are subject to</li>
            <li>Resolving pending complaints or claims</li>
            <li>Addressing any liability that might arise from data processing</li>
          </ul>

          <h3>4. What is the legal basis for processing your data?</h3>
          <p>The legal basis for processing your data is:</p>
          <ul>
            <li>The execution of the service contract</li>
            <li>The consent of the data subject</li>
            <li>Compliance with applicable legal obligations</li>
            <li>The legitimate interest of the controller</li>
          </ul>

          <h3>5. To which recipients will your data be communicated?</h3>
          <p>Your data may be communicated to:</p>
          <ul>
            <li>Nectios group companies for internal administrative purposes</li>
            <li>Service providers necessary for service delivery</li>
            <li>Public Administrations in cases provided by Law</li>
          </ul>

          <h3>6. What are your rights?</h3>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Rectify inaccurate data</li>
            <li>Request deletion</li>
            <li>Object to processing</li>
            <li>Request processing limitation</li>
            <li>Request data portability</li>
          </ul>

          <p>You can exercise these rights by sending an email to legal@nectios.com, attaching a copy of your ID or equivalent identification document.</p>

          <h3>7. Security Measures</h3>
          <p>We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:</p>
          <ul>
            <li>Data encryption</li>
            <li>Ability to ensure confidentiality, integrity, and availability</li>
            <li>Ability to restore access to data</li>
            <li>Regular testing and evaluation process</li>
          </ul>
        `
      },
      ca: {
        title: 'Política de Privacitat',
        content: `
          <h2>Política de Privacitat i Protecció de Dades</h2>
          
          <p>En compliment de la normativa vigent en matèria de protecció de dades personals, l'informem dels següents aspectes:</p>
          
          <h3>1. Qui és el responsable del tractament de les seves dades?</h3>
          <p>
            <strong>Responsable:</strong> Copernic Technologies S.L.<br>
            <strong>Adreça:</strong> Plaça Doctor Letamendi 37, 08007 Barcelona, Espanya<br>
            <strong>Email:</strong> legal@nectios.com<br>
            <strong>CIF:</strong> B67073130
          </p>

          <h3>2. Amb quina finalitat tractem les seves dades personals?</h3>
          <p>Tractem la informació que ens facilita amb les següents finalitats:</p>
          <ul>
            <li>Gestionar el servei contractat i mantenir la relació contractual</li>
            <li>Atendre les seves consultes i sol·licituds</li>
            <li>Enviar-li comunicacions comercials sobre els nostres productes i serveis</li>
            <li>Millorar els nostres serveis i l'experiència d'usuari</li>
            <li>Complir amb les nostres obligacions legals</li>
          </ul>

          <h3>3. Durant quant temps conservarem les seves dades?</h3>
          <p>Les dades personals es conservaran mentre es mantingui la relació comercial i no se sol·liciti la seva supressió. Fins i tot sol·licitada la supressió, es mantindran bloquejades durant el temps necessari, i limitant el seu tractament, únicament per a:</p>
          <ul>
            <li>Complir amb les obligacions legals/contractuals de qualsevol tipus a què estiguem sotmesos</li>
            <li>Resoldre queixes o reclamacions pendents</li>
            <li>Fer front a qualsevol responsabilitat que pogués derivar-se del tractament de les dades</li>
          </ul>

          <h3>4. Quina és la legitimació per al tractament de les seves dades?</h3>
          <p>La base legal per al tractament de les seves dades és:</p>
          <ul>
            <li>L'execució del contracte de serveis</li>
            <li>El consentiment de l'interessat</li>
            <li>El compliment d'obligacions legals aplicables</li>
            <li>L'interès legítim del responsable</li>
          </ul>

          <h3>5. A quins destinataris es comunicaran les seves dades?</h3>
          <p>Les seves dades podran ser comunicades a:</p>
          <ul>
            <li>Empreses del grup Nectios per a fins administratius interns</li>
            <li>Proveïdors de serveis necessaris per a la prestació del servei</li>
            <li>Administracions Públiques en els casos previstos per la Llei</li>
          </ul>

          <h3>6. Quins són els seus drets?</h3>
          <p>Té dret a:</p>
          <ul>
            <li>Accedir a les seves dades personals</li>
            <li>Rectificar les dades inexactes</li>
            <li>Sol·licitar la seva supressió</li>
            <li>Oposar-se al tractament de les seves dades</li>
            <li>Sol·licitar la limitació del tractament</li>
            <li>Sol·licitar la portabilitat de les seves dades</li>
          </ul>

          <p>Pot exercir aquests drets enviant un correu electrònic a legal@nectios.com, adjuntant còpia del seu DNI o document identificatiu equivalent.</p>

          <h3>7. Mesures de seguretat</h3>
          <p>Apliquem mesures tècniques i organitzatives apropiades per garantir un nivell de seguretat adequat al risc, incloent:</p>
          <ul>
            <li>Xifratge de dades</li>
            <li>Capacitat de garantir la confidencialitat, integritat i disponibilitat</li>
            <li>Capacitat de restaurar l'accés a les dades</li>
            <li>Procés de verificació i avaluació regular</li>
          </ul>
        `
      }
    }
  }
} 